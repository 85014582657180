import store from "@/store"
import { COMMITS, GETTERS } from "@/store/constants"
import { http } from "@/utils"
import { eventBus } from "@/plugins/eventBus.plugin"
import uiEvents from "@/configs/uiEvents"
import router from '@/router'

/** @returns {User} */
const getUser = () =>
  JSON.parse(JSON.stringify(store.getters[GETTERS.USER] || {}))

export async function createNewUser(
  auth0Profile,
  fullname,
  invitationId = null,
) {
  try {
    const res = await (
      await http()
    ).post("/user/create", { ...auth0Profile, fullname, invitationId })
    
    const { user, redirectUrl } = res.data
    
    // Handle redirection
    if (redirectUrl) {
      await router.push(redirectUrl)
    } else {
      // Default redirect if no redirectUrl provided
      await router.push('/')
    }
    
    return { user, redirectUrl }
  } catch (error) {
    throw error
  }
}

export async function validateUserInvitation(auth0Profile) {
  const res = await (
    await http()
  ).post("/user/validate-invitation", { ...auth0Profile })
  return {
    adminAccount: res.data.adminAccount,
    invitation: res.data.invitation,
  }
}

/**
 * update user info
 * @param {{username: string}} payload
 * @returns {Promise<{user: User; account: Account}>}
 */
export const updateUserInfo = async ({ fullname, avatar, language }) => {
  const user = getUser()
  if (!user || !user._id) {
    throw new Error("No logged in user found!")
  }
  const res = await (
    await http()
  ).put(`/user/profile/${user._id}`, { fullname, avatar, language })
  if (res.data.user) store.commit(COMMITS.SET_USER, res.data.user)
  if (res.data.account) store.commit(COMMITS.SET_ACCOUNT, res.data.account)
  return res.data.data
}

/**
 * upload and save user avatar
 * @param {File} file
 */
export const uploadProfileAvatar = async (file) => {
  const payload = new FormData()
  payload.append("file", file)
  const res = await (await http()).post("/user/upload-profile-pic", payload)
  if (res.data.user) store.commit(COMMITS.SET_USER, res.data.user)
}

/**
 * Remove a team member
 * @param {string} memberEmail
 * @returns {Promise<Account>}
 */
export const deleteTeamMember = async (memberEmail, unsubseat = false) => {
  const res = await (
    await http()
  ).delete("/team/members", {
    params: {
      email: encodeURIComponent(memberEmail),
      unsubseat: unsubseat.toString(),
    },
  })
  return {
    team: res.data.team,
    user: res.data.user,
  }
}

/**
 * @param {string} memberEmail email of the new user
 */
export const sendInvitation = async (memberEmail) => {
  const payload = {
    email: memberEmail,
  }
  const res = await (await http()).post("/team/members", payload)
  return {
    team: res.data.team,
    user: res.data.user,
  }
}

/**
 * Get user's current activities
 */
export const getUserActivities = async (filter) => {
  try {
    const filterBy =
      filter === "This month"
        ? "month"
        : filter === "This week"
        ? "week"
        : filter === "Today"
        ? "today"
        : "all"
    const res = await (await http()).get(`/activities?filter=${filterBy}`)
    return res.data.activities
  } catch (err) {
    window.devErr(err.response || err)
    return []
  }
}

export const getDownloadProfileUrl = async (type = "json") => {
  const res = await (
    await http()
  ).post(`/exporter/create/profile-data?type=${type.toLowerCase()}`)
  return res.data.downloadUrl
}

export const getAffiliateProfile = async () => {
  const res = await (await http()).post("/affiliate/profile")
  if (res.data.user) store.commit(COMMITS.SET_USER, res.data.user)
  return res.data.promoter
}

/**
 * get all the user info including stripe profile.
 * @param {string} userId
 * @returns {Promise<{user: object; account: object; stripeProfile: object}>}
 */
export const getAllUserInfo = async (userId) => {
  const { data } = await (await http()).get(`/user/profile/${userId}`)
  return {
    user: data.user || null,
    subscription: data.subscription || null,
    team: data.team || null,
    stripeProfile: data.stripeProfile || null,
    cards: data.cards || [],
  }
}

/**
 * Change the user's default payment option (card).
 * @param {string} cardId - Id of the card.
 */
export async function changeDefaultPaymentOption(cardId) {
  try {
    const res = await (await http()).put(`/user/default-payment`, { cardId })
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: "Default payment method changed.",
      severity: "success",
    })
    return res.data
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: "Unable to change the default payment method.",
      severity: "error",
    })
    return null
  }
}

export async function createNewStripeSession() {
  const res = await (await http()).post("/user/stripe-session")
  return res.data.redirectUrl
}

export async function markWelomeCardAsViewed() {
  try {
    const user = getUser()
    await (
      await http()
    ).put(`/user/profile/${user._id}/view-welcome-cards/first-login`)
  } catch (err) {
    window.devErr(err.respon || err)
  }
}

export async function markProjectPageAsViewed() {
  try {
    const user = getUser()
    await (
      await http()
    ).put(`/user/profile/${user._id}/view-welcome-cards/first-project-page`)
  } catch (err) {
    window.devErr(err.respon || err)
  }
}

export async function markToolModeAsViewed() {
  try {
    const user = getUser()
    await (
      await http()
    ).put(`/user/profile/${user._id}/view-welcome-cards/first-tool-mode`)
  } catch (err) {
    window.devErr(err.respon || err)
  }
}

export async function markAgiModeAsViewed() {
  try {
    const user = getUser()
    await (
      await http()
    ).put(`/user/profile/${user._id}/view-welcome-cards/first-agi-mode`)
  } catch (err) {
    window.devErr(err.respon || err)
  }
}

/**
 * @param {string} newEmail
 * @returns {Promise<{user:User; redirectUrl: string}>}
 */
export async function updatePrimaryEmail(newEmail) {
  const user = getUser()
  await (
    await http()
  ).put(`/user/profile/${user._id}/update-email`, { email: newEmail })
}

/**
 * @returns {Promise<{user:User}>}
 */
export async function removeSecondaryEmail() {
  const user = getUser()
  const { data } = await (
    await http()
  ).delete(`/user/profile/${user._id}/update-email`)
  return data.user
}

/**
 * @param {string} memberId
 * @param {Record<string, string>} member
 * @returns {Promise<Record<string, string>>}
 * @throws {import("axios").AxiosError}
 */
export async function updateTeammateInfo(memberId, member) {
  const res = await (
    await http()
  ).put(
    "/team/members",
    {
      fullname: member.fullname,
      email: member.email,
      role: member.role,
    },
    {
      params: {
        mid: memberId,
      },
    },
  )
  return res.data.team
}