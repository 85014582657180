import axios from "axios"
import auth0 from "@/plugins/auth0.plugin"

/**
 * Make an authorized request to the backend api.
 * If the there is no authenticated user then it will simple return an axios instance without the auth token.
 *
 * @param {import("@auth0/auth0-spa-js").Auth0Client} auth0
 * @returns {Promise<import("axios").AxiosInstance>}
 *
 * @example
 * // Using the http0 function
 * async function authenticateUser(userInfo) {
 *   try {
 *     // the $auth0 is present only inside a Vue component
 *     const response = await (await http0(this.$auth0)).post("/auth0", {...userInfo});
 *     console.log(response.data);x
 *   } catch (error) {
 *     window.devErr('Error authenticating user:', error);
 *   }
 * }
 */
export const http = async () => {
  const baseURL = import.meta.env.VITE_API_URL + "/api"
  if (auth0 && auth0.isAuthenticated.value) {
    const accessToken = await auth0.getAccessTokenSilently()
    return axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }
  return axios.create({
    withCredentials: true,
    baseURL,
  })
}

export const agentHttp = async () => {
  const baseURL = import.meta.env.VITE_CHATBOT_URL
  if (auth0 && auth0.isAuthenticated.value) {
    const accessToken = await auth0.getAccessTokenSilently()
    return axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }
  return axios.create({
    withCredentials: true,
    baseURL,
  })
}

export const hsAgiHttp = async () => {
  const baseURL = import.meta.env.VITE_HSAGI_HTTP_URL
  console.log("[Debug] Creating AGI client:", {
    auth0Exists: !!auth0,
    isAuthenticated: auth0?.isAuthenticated?.value,
    baseURL,
    env: import.meta.env
  })
  
  if (auth0 && auth0.isAuthenticated.value) {
    try {
      const accessToken = await auth0.getAccessTokenSilently()
      console.log("[Debug] Got access token:", {
        tokenExists: !!accessToken,
        tokenLength: accessToken?.length,
        tokenPrefix: accessToken?.substring(0, 10) + '...'
      })
      
      const instance = axios.create({
        baseURL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Accept': 'application/json'
        },
        timeout: 10 * 60 * 1000, // 10 mins
        withCredentials: true, // Important for CORS
      })

      // Add request interceptor for debugging
      instance.interceptors.request.use(
        config => {
          // Ensure v1 prefix for API routes
          if (!config.url.startsWith('/v1')) {
            config.url = `/v1${config.url}`
          }
          
          console.log("[Debug] Outgoing request:", {
            url: `${config.baseURL}${config.url}`,
            method: config.method,
            baseURL: config.baseURL,
            hasAuth: !!config.headers?.Authorization,
            headers: Object.keys(config.headers || {})
          })
          return config
        },
        error => {
          console.error("[Debug] Request error:", {
            message: error.message,
            config: error.config
          })
          return Promise.reject(error)
        }
      )

      // Add response interceptor for better error handling
      instance.interceptors.response.use(
        response => {
          console.log("[Debug] Response received:", {
            status: response.status,
            statusText: response.statusText,
            url: response.config.url,
            size: JSON.stringify(response.data).length
          })
          return response
        },
        error => {
          console.error("[Debug] Response error:", {
            message: error.message,
            status: error.response?.status,
            statusText: error.response?.statusText,
            url: error.config?.url,
            data: error.response?.data,
            headers: error.response?.headers
          })

          if (error.response?.status === 400) {
            if (error.response.data?.error?.includes('No JSON')) {
              error.message = 'Invalid request format. Please try again.'
            } else {
              const message = error.response.data?.message || 'Unknown AGI error'
              error.message = `AGI Request failed: ${message}`
            }
          }
          return Promise.reject(error)
        }
      )

      return instance
    } catch (error) {
      console.error('[Debug] Error creating AGI client:', {
        message: error.message,
        stack: error.stack,
        auth0State: {
          exists: !!auth0,
          isAuthenticated: auth0?.isAuthenticated?.value
        }
      })
      return axios.create({
        withCredentials: true,
        baseURL,
      })
    }
  }
  return axios.create({
    withCredentials: true,
    baseURL,
  })
}

// public async createSupportTicket(req: Request, res: Response) {
//     try {
//       const {
//         subject,
//         description,
//         applications_id,
//         support_tickets_statuses_id,
//         support_tickets_types_id,
//         users_id,
//         assignee_ids = [],
//         source_id,
//         links = [],
//       } = req.body;
//       //@ts-ignore
//       const added_by = req.user_id;
//       //@ts-ignore
//       const display_name = req.display_name;
//       const ticketData = {
//         subject,
//         description,
//         applications_id,
//         support_tickets_statuses_id,
//         support_tickets_types_id,
//         users_id,
//         added_by,
//         date_created: new Date(),
//         date_updated: new Date()
//       };
//       let supportTicket = await dbWriter.supportTickets.create(ticketData);
//       let assigneesData = [];
//       if (assignee_ids.length) {
//         assigneesData = assignee_ids.map((user_id: any) => ({
//           support_tickets_id: supportTicket.id,
//           users_id: user_id,
//           date_created: new Date(),
//         }));
//       }
//       const supportTicketLinksData = links.map((link: any) => ({
//         support_tickets_services_id: link.support_tickets_services_id,
//         url: link.url,
//         support_tickets_id: supportTicket.id,
//         service_reference_id: link.service_reference_id,
//         is_source: source_id == link.support_tickets_services_id ? 1 : 0,
//         date_created: new Date(),
//       }));
//       let [assigneesCreated, _, linksCreated] = await Promise.all([
//         dbWriter.supportTicketAssignees.bulkCreate(assigneesData),
//         dbWriter.supportTicketActivities.create({
//           support_tickets_id: supportTicket.id,
//           users_id: users_id,
//           activity: `Ticket Created by ${display_name}: ${subject}`,
//           date_created: new Date(),
//         }),
//         dbWriter.supportTicketLinks.bulkCreate(supportTicketLinksData),
//       ]);
//       assigneesCreated = JSON.parse(JSON.stringify(assigneesCreated));
//       linksCreated = JSON.parse(JSON.stringify(linksCreated));
//       supportTicket = JSON.parse(JSON.stringify(supportTicket));
//       new SuccessResponse("Ticket Created successfully!", {
//         //@ts-ignore
//         token: req.token,
//         ...{
//           ...supportTicket,
//           links: linksCreated,
//           assignees: assigneesCreated,
//         },
//       }).send(res);
//     } catch (e: any) {
//       ApiError.handle(new BadRequestError(e.message), res);
//     }
//   }