import { computed } from 'vue'
import store from '@/store'
import { GETTERS, COMMITS } from '@/store/constants'
import { eventBus } from './eventBus.plugin'
import uiEvents from '@/configs/uiEvents'
import router from '@/router'

export const tokenManager = {
  tokenInfo: computed(() => store.getters[GETTERS.TOKEN_INFO]),
  user: computed(() => store.getters[GETTERS.USER]),
  subscription: computed(() => store.getters[GETTERS.SUBSCRIPTION]),

  checkTokenBalance(requiredTokens) {
    if (this.tokenInfo.value.balance < requiredTokens) {
      if (this.isFreeTrial()) {
        this.showTokenDepletionMessage("Please upgrade to continue", "You've reached the limit of your free trial. Upgrade now to keep using the service.", true)
      } else {
        this.showTokenDepletionMessage("You ran out of tokens!", "You don't have enough tokens to continue using HelloScribe. Please purchase new tokens to continue.", false)
      }
      return false
    }
    return true
  },

  isFreeTrial() {
    return (
      this.subscription.value &&
      this.subscription.value.planName &&
      this.subscription.value.planName.toLowerCase() === "free trial"
    );
  },

  showTokenDepletionMessage(message, description, isFreeTrial) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, {
      message,
      description,
      isFreeTrial
    })
  },

  updateTokenInfo(newTokenInfo) {
    console.log("[Token Debug] Updating token info via tokenManager:", newTokenInfo)
    store.commit(COMMITS.SET_TOKEN_INFO, newTokenInfo)
  },

  updateBalance(newBalance) {
    console.log("[Token Debug] Updating token balance via tokenManager:", newBalance)
    store.commit(COMMITS.UPDATE_TOKEN_BALANCE, newBalance)
  },

  handleTokenDepletionMidGeneration(partialResults, isFreeTrial) {
    if (isFreeTrial) {
      this.showTokenDepletionMessage(
        "Please upgrade to continue",
        "You've reached the limit of your free trial. Upgrade now to keep using the service.",
        true
      )
    } else {
      this.showTokenDepletionMessage(
        "You ran out of tokens!",
        "You don't have enough tokens to continue using HelloScribe. Please purchase new tokens to continue.",
        false
      )
    }

    // Handle partial results if needed
    if (partialResults) {
      // Implement logic to handle partial results
      // For example, you might want to update the UI with the partial results
      eventBus.emit(uiEvents.GLOBAL.UPDATE_PARTIAL_RESULTS, partialResults);
    }
  }
};

export default {
  install(app) {
    app.config.globalProperties.$tokenManager = tokenManager;
    app.provide('tokenManager', tokenManager);
  }
}
