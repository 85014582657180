import { AGENT_CHAT_HISTORY_PER_PAGE } from "@/configs/constants"

/** @type {import("vuex").GetterTree} */
export default {
  getChatHistory(state) {
    const currentPage = state.chatHistoryCurrentPage
    const chatHistory = state.chatHistory
    console.log("[Debug] Getting paginated chat history:", {
      currentPage,
      totalThreads: chatHistory.length,
      start: (currentPage - 1) * AGENT_CHAT_HISTORY_PER_PAGE,
      end: AGENT_CHAT_HISTORY_PER_PAGE * currentPage
    });
    const start = (currentPage - 1) * AGENT_CHAT_HISTORY_PER_PAGE
    const end = AGENT_CHAT_HISTORY_PER_PAGE * currentPage
    const data = chatHistory.slice(start, end)
    return data
  },

  getChatHistoryRepo(state) {
    return state.chatHistoryRepo
  },

  getSessionId(state) {
    return state.sessionId
  },

  getUserReply(state) {
    return state.userReply
  },

  getIsUserReply(state) {
    return state.isUserReply
  },

  getShowStopBtn(state) {
    return state.showStopBtn
  },

  getObjective(state) {
    return state.objective
  },

  getTaskList(state) {
    return state.taskList
  },

  getHasNextPage(state) {
    return state.hasNextPage
  },

  getChatHistoryCurrentPage(state) {
    return state.chatHistoryCurrentPage
  },

  getShowQuickStarts(state) {
    return state.showQuickStarts
  },
}
