import { computed, ref, watch, onMounted } from "vue"
import store from "@/store"
import { GETTERS, COMMITS } from "@/store/constants"
import uiEvents from "@/configs/uiEvents"
import { eventBus } from "./eventBus.plugin"
import { agentService } from "@/services"
import { hsAgiHttp, parseError } from "@/utils"
import dayjs from "dayjs"
import { tokenManager } from '@/plugins/tokenManager'
import axios from "axios"

// Network request configuration
const NETWORK_CONFIG = {
  RETRY: {
    MAX_ATTEMPTS: 3,
    BASE_DELAY: 1000,
    MAX_DELAY: 8000
  },
  TIMEOUT: {
    REQUEST: 30000,
    HEALTH_CHECK: 5000
  },
  HEADERS: {
    DEFAULT: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      "Pragma": "no-cache",
      "X-Requested-With": "XMLHttpRequest"
    }
  }
}

// Request states for better tracking
const REQUEST_STATE = {
  IDLE: 'idle',
  PENDING: 'pending',
  RETRYING: 'retrying',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
}

const SELECTED_THREAD_KEY = "helloscribe.locals.user.selected_thread_id"
const UPLOADED_FILE_LIST_KEY =
  "helloscrhelloscribe.locals.user.uploaded_files_list"

const UPDATE_TYPES = {
  NEW_THREAD: "new_thread",
  CONTINUE_THREAD: "continue_thread",
  NEW_QUESTION: "new_question",
  POST_ANSWER: "post_answer",
  SKIP_CLARIFICATION: "skip_clarification",
  NEW_TASK_LIST: "new_task_list",
  NEW_SUMMARY: "new_summary",
  TASK_APPROVAL: "task_approval",
  TASK_COMPLETION: "task_completion",
  TASK_REDO: "task_redo",
  THREAD_COMPLETED: "thread_completed",
  TASK_UPDATE: "task_update",
  THREAD_UPDATE: "thread_update",
  ERROR: "error",
}

const AGI_STEP = {
  CLARIFICATION: 1,
  CREATE_TASK_LIST: 2,
  SUMMARIZATION: 3,
  APPROVAL: 4,
  TASK_EXECUTION: 5,
}

const user = computed(() => store.getters[GETTERS.USER])
const tokenInfo = computed(() => store.getters[GETTERS.TOKEN_INFO])
/** @type {import("vue").Ref<AgiThread[]>} */
const threadList = computed(() => store.getters[GETTERS.AGENT_CHAT_HISTORY])
const selectedProject = computed(() => store.getters[GETTERS.SELECTED_PROJECT])
/** @type {import("vue").Ref<AgiThread|null>} */
const selectedThread = ref(null)
const agiBusy = ref(false)
const userInput = ref("")
const allowAutoScrolling = ref(true)
const language = ref({
  input: "EN-US",
  output: "EN-US",
})
const uploadedFiles = ref([])
const uploadingFiles = ref(false)
const requestState = ref(REQUEST_STATE.IDLE)
/** @type {AbortController} */
let abortController = new AbortController()

/**
 * Checks server connectivity with better error handling
 * @returns {Promise<boolean>}
 */
async function checkServerConnectivity() {
  try {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), NETWORK_CONFIG.TIMEOUT.HEALTH_CHECK)
    
    // Get the base URL from environment
    const baseUrl = import.meta.env.VITE_HSAGI_HTTP_URL
    if (!baseUrl) {
      console.error('[Debug] Missing HSAGI base URL:', {
        env: import.meta.env,
        baseUrl
      })
      return false
    }

    // Get authenticated client first
    const http = await hsAgiHttp()
    
    // Log auth state
    console.log('[Debug] Auth state:', {
      hasAuth: !!http.defaults.headers.Authorization,
      baseURL: http.defaults.baseURL,
      authPrefix: http.defaults.headers.Authorization?.substring(0, 15) + '...'
    })

    if (!http.defaults.headers.Authorization) {
      console.error('[Debug] No auth token available')
      return false
    }

    // Try health check endpoints
    const endpoints = [
      'health',
      'agi/health',
      'status'  // Fallback status endpoint
    ]
    
    for (const endpoint of endpoints) {
      try {
        console.log("[Debug] Trying health check endpoint:", {
          url: `${http.defaults.baseURL}${endpoint}`,
          hasAuth: !!http.defaults.headers?.Authorization
        })
        
        const response = await http.head(endpoint, {
          signal: controller.signal,
          headers: {
            ...NETWORK_CONFIG.HEADERS.DEFAULT,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        })
        
        if (response.status === 200) {
          clearTimeout(timeoutId)
          console.log("[Debug] Health check succeeded:", {
            endpoint,
            status: response.status
          })
          return true
        }
      } catch (e) {
        console.warn(`[Debug] Health check failed for ${endpoint}:`, {
          error: e.message,
          status: e.response?.status,
          baseURL: http.defaults.baseURL,
          hasAuth: !!http.defaults.headers?.Authorization
        })
        continue
      }
    }
    
    return false
  } catch (e) {
    console.error('[Debug] Server connectivity check failed:', {
      error: e.message,
      stack: e.stack,
      networkStatus: navigator.onLine ? 'online' : 'offline'
    })
    return false
  }
}

/**
 * Creates an HTTP request with proper error handling and timeout
 * @param {Function} requestFn - The request function to execute
 * @param {Object} options - Request options
 * @returns {Promise} - The request promise
 */
async function createRequest(requestFn, options = {}) {
  const controller = new AbortController()
  const { signal } = controller
  
  // Create timeout promise
  const timeoutPromise = new Promise((_, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('Request timeout'))
    }, options.timeout || NETWORK_CONFIG.TIMEOUT.REQUEST)
    
    // Cleanup timeout if request completes
    signal.addEventListener('abort', () => clearTimeout(timeoutId))
  })

  try {
    // Wait for document to be ready and Cloudflare's Rocket Loader to complete
    if (document.readyState !== 'complete') {
      await new Promise(resolve => {
        const checkReady = () => {
          if (document.readyState === 'complete') {
            resolve()
          } else {
            requestAnimationFrame(checkReady)
          }
        }
        checkReady()
      })
    }

    // Get fresh auth token before making request
    const http = await hsAgiHttp()
    if (!http.defaults.headers.Authorization) {
      throw new Error('No authorization token available')
    }

    // Execute request with timeout race
    return await Promise.race([
      requestFn({ 
        signal,
        headers: {
          ...NETWORK_CONFIG.HEADERS.DEFAULT,
          Authorization: http.defaults.headers.Authorization
        }
      }),
      timeoutPromise
    ])
  } finally {
    controller.abort() // Ensure cleanup
  }
}

/**
 * Checks if the error is retryable
 * @param {Error} error - The error to check
 * @returns {boolean}
 */
function isRetryableError(error) {
  return (
    error.code === 'ERR_NETWORK' ||
    error.message === 'Network Error' ||
    error.message === 'Request timeout' ||
    error.response?.status === 0 ||
    error.response?.status === 408 ||
    error.response?.status === 429 ||
    error.response?.status >= 500 ||
    error.response?.data?.error === "504 Deadline Exceeded"
  )
}

watch(selectedThread, (t) => {
  if (!t) {
    localStorage.removeItem(SELECTED_THREAD_KEY)
    return
  }
  localStorage.setItem(SELECTED_THREAD_KEY, t.id)
})

watch(uploadedFiles, (files) => {
  localStorage.setItem(UPLOADED_FILE_LIST_KEY, JSON.stringify(files))
})

onMounted(() => {
  const cachedList = localStorage.getItem(UPLOADED_FILE_LIST_KEY)
  if (!cachedList) {
    uploadedFiles.value = []
    return
  }
  try {
    const list = JSON.parse(cachedList)
    uploadedFiles.value = list.filter(
      (item) =>
        dayjs(item.exp).isValid() &&
        dayjs(item.exp).isAfter(Date.now(), "minute"),
    )
  } catch (err) {
    uploadedFiles.value = []
  }
})

async function approveTaskList(approve = false) {
  agiBusy.value = true
  selectedThread.value.loading_summarization = true

  if (!approve) {
    selectedThread.value.tasks = []
    selectedThread.value.tasks_summary = ""
    await agiGenerate({ approve })
    return
  }
  await agiGenerate({ approve })
}

async function startIncompleteThread(id, thread = null) {
  const threads = store.getters[GETTERS.AGENT_CHAT_HISTORY]
  const t = thread || threads.find((t) => t.id === id)
  if (!t) {
    console.log("[Debug] Thread selection failed in startIncompleteThread:", {
      threadId: id,
      selectedThread: selectedThread.value,
      networkStatus: navigator.onLine ? 'online' : 'offline',
      threads: threads.length
    });
    window.devErr("Selected thread not found")
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Thread Selection Error",
      body: "Unable to find or load the selected conversation. This may happen if:\n\n" +
            "1. The conversation was deleted\n" +
            "2. Your session has expired\n" +
            "3. There was a temporary network issue\n\n" +
            "Please try:\n" +
            "1. Refresh the page\n" +
            "2. Start a new conversation\n" +
            "3. If the issue persists, clear your browser cache",
    })
    return
  }

  try {
    selectedThread.value = await agentService.getThreadById(t.id || t._id)
  } catch (err) {
    console.log("[Debug] Thread fetch failed in startIncompleteThread:", {
      threadId: t.id || t._id,
      error: err?.response?.data || err,
      networkStatus: navigator.onLine ? 'online' : 'offline',
      stack: err.stack
    });
    window.devErr(err?.response?.data || err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Selected thread not found",
      body: parseError(err),
    })
    selectedThread.value = null
    return
  }

  if (selectedThread.value.step === AGI_STEP.CLARIFICATION) {
    return
  }
  if (
    selectedThread.value.step === AGI_STEP.APPROVAL &&
    !selectedThread.value.tasks_confirmed
  ) {
    return
  }
  await agiGenerate({})
}

async function sendMsg() {
  if (!tokenManager.checkTokenBalance(1)) {
    // Show token run out modal
    if (tokenManager.isFreeTrial()) {
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, {
        message: "Please upgrade to continue",
        description:
          "You've reached the limit of your free trial. Please upgrade to continue.",
        isFreeTrial: true,
      });
    } else {
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, {
        message: "You ran out of tokens!",
        description:
          "You don't have enough tokens to continue using HelloScribe. Please purchase new tokens to continue.",
        isFreeTrial: false,
      });
    }
    return;
  }

  agiBusy.value = true
  const msg = userInput.value.trimEnd()
  userInput.value = ""
  if (!msg || msg.length < 2) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Please enter a text to send!",
      body: "Message length should be 2 or more characters.",
    })
    agiBusy.value = false
    return
  }

  const payload = {
    language: "English",
    objective: msg,
    answer: msg,
  }

  if (selectedThread.value) {
    payload.question_idx = selectedThread.value.clarifications.length - 1
    selectedThread.value.clarifications[payload.question_idx].answer = msg
  } else {
    selectedThread.value = {
      objective: payload.objective,
      loading_clarification: true,
      clarifications: [],
      step: AGI_STEP.CLARIFICATION,
      tasks: [],
    }
  }

  try {
    await agiGenerate(payload)
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

// Removed unused validateThreadTransition function

/**
 * Validates the payload before sending to the API
 * @param {any} payload - The payload to validate
 * @throws {Error} If the payload is invalid
 */
function validatePayload(payload) {
  // Ensure payload is a valid object
  if (!payload || typeof payload !== 'object' || Array.isArray(payload)) {
    throw new Error('Invalid payload format')
  }

  // Required fields check
  const requiredFields = ['user_id', 'update_type', 'language']
  requiredFields.forEach(field => {
    if (!payload[field]) {
      throw new Error(`Missing required field: ${field}`)
    }
  })

  // Validate user_id
  if (!payload.user_id?.trim()) {
    throw new Error("Invalid user ID")
  }

  // Validate filenames
  if (!Array.isArray(payload.filenames)) {
    payload.filenames = [] // Ensure it's always an array
  }
  
  // Validate each filename
  payload.filenames = payload.filenames.filter(filename => 
    filename && typeof filename === 'string'
  ).map(filename => filename.trim())

  // Validate update_type
  if (!Object.values(UPDATE_TYPES).includes(payload.update_type)) {
    throw new Error(`Invalid update type: ${payload.update_type}`)
  }

  // Validate language
  if (!payload.language || typeof payload.language !== 'object') {
    throw new Error("Invalid language format")
  }
  if (!payload.language.input || !payload.language.output) {
    throw new Error("Language must include input and output")
  }

  // Ensure language values are strings
  payload.language.input = String(payload.language.input).trim()
  payload.language.output = String(payload.language.output).trim()

  // Validate thread specific data
  if (payload.thread_id) {
    payload.thread_id = String(payload.thread_id).trim()
  }

  // Validate token_info if present
  if (payload.token_info) {
    if (!payload.token_info.granted || !payload.token_info.balance) {
      throw new Error("Invalid token info format")
    }
    // Ensure numeric values
    payload.token_info.granted = Number(payload.token_info.granted)
    payload.token_info.balance = Number(payload.token_info.balance)
  }

  // Clean the payload by removing undefined/null values
  Object.keys(payload).forEach(key => {
    if (payload[key] === undefined || payload[key] === null) {
      delete payload[key]
    }
  })

  // Test JSON serialization
  try {
    JSON.stringify(payload)
  } catch (err) {
    console.error('Payload serialization error:', err)
    throw new Error('Payload contains non-serializable data')
  }

  return payload
}

/**
 * Validates thread state
 * @param {any} thread - The thread to validate
 * @throws {Error} If the thread state is invalid
 */
function validateThreadState(thread) {
  if (!thread) {
    throw new Error("Thread is required")
  }

  if (!Object.values(AGI_STEP).includes(thread.step)) {
    throw new Error(`Invalid thread step: ${thread.step}`)
  }

  if (thread.id && typeof thread.id !== 'string') {
    throw new Error("Invalid thread ID format")
  }

  return thread
}

/**
 * @param {{
 *   answer: string | undefined;
 *   approve: boolean;
 *   question_idx: number | undefined;
 *   objective: string | undefined;
 *   language: {input: string; output: string}}} data
 */
async function agiGenerate(data) {
  try {
    requestState.value = REQUEST_STATE.PENDING
    
    // Initial validation
    if (!user.value?._id || !tokenManager.tokenInfo.value?.granted) {
      throw new Error('Missing user or token information')
    }

    agiBusy.value = true
    renderLoadingUi()

    // Prepare payload
    const payload = {
      ...data,
      filenames: (uploadedFiles.value || [])
        .filter(f => f?.filename && typeof f.filename === 'string')
        .map(f => f.filename.trim()),
      user_id: user.value._id?.trim(),
      token_info: tokenManager.tokenInfo.value,
      language: {
        input: language.value.input,
        output: language.value.output
      }
    }

    // Set thread-specific data
    if (selectedThread.value?.id) {
      payload.thread_id = selectedThread.value.id
      payload.language = selectedThread.value.language || payload.language
    } else {
      payload.thread_id = ""
      payload.update_type = UPDATE_TYPES.NEW_THREAD
      payload.project_id = selectedProject.value?._id || null
    }

    // Store original state
    const originalState = selectedThread.value ? 
      JSON.parse(JSON.stringify(selectedThread.value)) : null

    let retryCount = 0
    let lastError = null

    while (retryCount < NETWORK_CONFIG.RETRY.MAX_ATTEMPTS) {
      try {
        requestState.value = retryCount > 0 ? REQUEST_STATE.RETRYING : REQUEST_STATE.PENDING

        // Check server connectivity before making request
        const isConnected = await checkServerConnectivity()
        if (!isConnected) {
          throw new Error('Unable to connect to server')
        }

        // Make request with proper auth and error handling
        const response = await createRequest(
          async ({ signal }) => {
            // Get authenticated client first
            const http = await hsAgiHttp()
            
            // Log request state
            console.log("[Debug] Creating request:", {
              baseURL: http.defaults.baseURL,
              hasAuth: !!http.defaults.headers.Authorization,
              authPrefix: http.defaults.headers.Authorization?.substring(0, 15) + '...',
              method: 'POST',
              endpoint: 'agi/generate',
              payloadSize: JSON.stringify(payload).length
            })

            if (!http.defaults.headers.Authorization) {
              throw new Error('No authorization token available')
            }

            if (!http.defaults.baseURL) {
              throw new Error('No base URL configured')
            }

            return http.post("agi/generate", payload, {
              signal,
              headers: {
                ...NETWORK_CONFIG.HEADERS.DEFAULT,
                Authorization: http.defaults.headers.Authorization
              }
            })
          }
        )

        // Handle successful response
        requestState.value = REQUEST_STATE.SUCCEEDED
        uploadedFiles.value = []
        
        const thread = response.data.data?.thread
        if (!thread) {
          throw new Error("No thread returned from the server")
        }

        // Update token info if provided
        if (response.data.data?.tokenInfo) {
          tokenManager.updateTokenInfo(response.data.data.tokenInfo)
        }

        // Update thread state
        selectedThread.value = thread
        setTimeout(autoScroll, 500)

        // Check if we need to continue
        if (
          thread.step === AGI_STEP.CLARIFICATION ||
          thread.step === AGI_STEP.APPROVAL ||
          thread.completed
        ) {
          agiBusy.value = false
          if (thread.completed) {
            const newList = threadList.value.filter((t) => t.id !== thread.id)
            newList.unshift(thread)
            store.commit(COMMITS.SET_AGENT_CHAT_HISTORY, newList)
          }
          return
        }

        // Continue if needed
        await agiGenerate({})
        return

      } catch (error) {
        lastError = error
        retryCount++

        // Enhanced error logging
        console.error("[agiGenerate] Request error:", {
          status: error?.response?.status,
          data: error?.response?.data,
          message: error.message,
          payload,
          attempt: retryCount,
          networkStatus: navigator.onLine ? 'online' : 'offline',
          documentState: document.readyState,
          rocketLoader: window.CFRL !== undefined,
          authHeader: error.config?.headers?.Authorization ? 'present' : 'missing',
          url: error.config?.url,
          baseURL: error.config?.baseURL
        })

        // Check for auth errors specifically
        if (error.response?.status === 401 || error.message === 'No authorization token available') {
          eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
            severity: "error",
            title: "Authentication Required",
            body: "Please sign in to continue. If you're already signed in, try refreshing the page.",
          })
          break
        }

        if (!isRetryableError(error) || retryCount >= NETWORK_CONFIG.RETRY.MAX_ATTEMPTS) {
          break
        }

        // Calculate delay with exponential backoff and jitter
        const delay = Math.min(
          NETWORK_CONFIG.RETRY.BASE_DELAY * Math.pow(2, retryCount - 1) + 
          Math.random() * 1000,
          NETWORK_CONFIG.RETRY.MAX_DELAY
        )

        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "warning",
          text: `Connection issue detected. Retrying in ${Math.round(delay/1000)} seconds... (Attempt ${retryCount}/${NETWORK_CONFIG.RETRY.MAX_ATTEMPTS})`
        })

        await new Promise(resolve => setTimeout(resolve, delay))
      }
    }

    // Handle final error state
    requestState.value = REQUEST_STATE.FAILED
    agiBusy.value = false

    // Restore original state if needed
    if (originalState && payload.update_type === UPDATE_TYPES.NEW_THREAD) {
      selectedThread.value = originalState
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
        severity: "info",
        text: "Restored previous state due to connection issues."
      })
    }

    // Show appropriate error message
    if (lastError?.name === "AbortError") {
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
        text: "Request was cancelled",
        severity: "error"
      })
    } else if (!navigator.onLine) {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "No Internet Connection",
        body: "Please check your internet connection and try again."
      })
    } else if (lastError?.response?.status === 401) {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Authentication Required",
        body: "Please sign in to continue. If you're already signed in, try refreshing the page.",
      })
    } else {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Connection Error",
        body: "Unable to connect to the server. Please try again in a few moments."
      })
    }

    throw lastError

  } catch (error) {
    agiBusy.value = false
    requestState.value = REQUEST_STATE.FAILED
    throw error
  }
}

async function redoTask(thread, taskId) {
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "Requesting a task redo, please wait...",
  })

  agiBusy.value = true
  selectedThread.value = thread
  selectedThread.value.step = AGI_STEP.TASK_EXECUTION
  selectedThread.value.completed = false

  for (let i = 0; i < selectedThread.value.tasks.length; i++) {
    if (selectedThread.value.tasks[i].id === taskId) {
      selectedThread.value.tasks[i].result = ""
      selectedThread.value.tasks[i].status = "In Progress"
      break
    }
  }

  await agiGenerate({
    task_id: taskId,
    instruction: "",
    update_type: UPDATE_TYPES.TASK_REDO,
  })
}

const startNewTask = () => {
  selectedThread.value = null
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    text: "Your chat has been moved to the completed chat histories list.",
  })
}

function autoScroll() {
  // if (!allowAutoScrolling.value) return
  window.scrollTo({
    top: window.innerHeight * 100,
    left: 0,
    behavior: "smooth",
  })
}

function renderLoadingUi() {
  if (!selectedThread.value) {
    return
  }
  if (selectedThread.value.step === AGI_STEP.CLARIFICATION) {
    selectedThread.value.loading_clarification = true
  } else if (selectedThread.value.step === AGI_STEP.CREATE_TASK_LIST) {
    selectedThread.value.loading_task_creation = true
  } else if (selectedThread.value.step === AGI_STEP.SUMMARIZATION) {
    selectedThread.value.loading_summarization = true
  }
}

function clearCanvas() {
  if (abortController) {
    abortController.abort("User stopped the AGI.")
  }
  selectedThread.value = null
  agiBusy.value = false
}

async function getLastSelectedThread() {
  const lastSelectedThreadId = localStorage.getItem(SELECTED_THREAD_KEY)
  if (!lastSelectedThreadId) return

  const maxRetries = 3
  let retries = 0

  while (retries < maxRetries) {
    try {
      const t = await agentService.getThreadById(lastSelectedThreadId)
      if (t && t.id) {
        if (t.completed) {
          localStorage.removeItem(SELECTED_THREAD_KEY)
        } else {
          selectedThread.value = t
          await startIncompleteThread(t.id, t)
        }
      }
      return // Success, exit the function
    } catch (err) {
      retries++
      console.error(`Attempt ${retries} failed:`, err)

      if (retries >= maxRetries || err.response) {
        // If we've reached max retries or received a response (even if it's an error), stop retrying
        console.log("[Debug] Thread fetch failed in getLastSelectedThread:", {
          threadId: lastSelectedThreadId,
          attempts: retries,
          error: err.response || err,
          networkStatus: navigator.onLine ? 'online' : 'offline',
          stack: err.stack
        });
        window.devErr(`Failed to fetch thread after ${retries} attempts:`, err.response || err)
        localStorage.removeItem(SELECTED_THREAD_KEY)
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "error",
          text: "Failed to load the last selected thread. Please try again later.",
        })
        return
      }

      // If it's a network error, wait before retrying
      if (err.code === 'ERR_NETWORK') {
        await new Promise(resolve => setTimeout(resolve, 2000 * retries)) // Exponential backoff
      }
    }
  }
}

async function skipClarificationStep() {
  const payload = {
    update_type: UPDATE_TYPES.SKIP_CLARIFICATION,
    user_id: user.value._id,
    thread_id: selectedThread.value.id,
    language: selectedThread.value.language,
    token_info: tokenInfo.value,
  }
  await agiGenerate(payload)
}

/**
 * @param {File[]} fileList
 */
async function uploadFiles(fileList) {
  if (fileList.length < 1) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: "No files selected",
      severity: "error",
    })
    return
  }
  try {
    uploadingFiles.value = true
    const form = new FormData()
    fileList.forEach((f) => {
      form.append("files", f)
    })
    const res = await (
      await hsAgiHttp()
    ).post("/agi/generate/upload-file", form, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    if (
      res.data.data &&
      "length" in res.data.data &&
      res.data.data.length > 0
    ) {
      uploadedFiles.value.push(...res.data.data)
    }
    store.commit(COMMITS.SET_SHOW_QUICK_STARTS, false)
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: parseError(err),
      severity: "error",
    })
  } finally {
    uploadingFiles.value = false
  }
}

async function removeUploadedFile(file) {
  try {
    uploadingFiles.value = true
    const filename = encodeURIComponent(file.filename)
    await (await hsAgiHttp()).delete(`/agi/generate/upload-file/${filename}`)
    uploadedFiles.value = uploadedFiles.value.filter(
      (f) => f.filename !== file.filename,
    )
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: parseError(err),
      severity: "error",
    })
  } finally {
    uploadingFiles.value = false
  }
}

async function newQuestion(question) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!question || question.length < 2) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Question is too short",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.NEW_QUESTION,
      question,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  } finally {
    agiBusy.value = false
  }
}

async function createNewTaskList() {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  agiBusy.value = true
  selectedThread.value.loading_task_creation = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.NEW_TASK_LIST,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function generateNewSummary() {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  agiBusy.value = true
  selectedThread.value.loading_summarization = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.NEW_SUMMARY,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function completeTask(taskId) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!taskId) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "No task specified",
    })
    return
  }

  const task = selectedThread.value.tasks.find(t => t.id === taskId)
  if (!task) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Task not found",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.TASK_COMPLETION,
      task_id: taskId,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function updateTask(taskId, updates) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!taskId || !updates) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Invalid task update parameters",
    })
    return
  }

  const task = selectedThread.value.tasks.find(t => t.id === taskId)
  if (!task) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Task not found",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.TASK_UPDATE,
      task_id: taskId,
      updates,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function updateThread(updates) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!updates) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "No updates provided",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.THREAD_UPDATE,
      updates,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

/************* the plugin **************/
export default {
  /**
   *  @param {import("vue").App} app
   */
  install(app) {
    const hsAgi = {
      threadList,
      selectedThread,
      agiBusy,
      userInput,
      allowAutoScrolling,
      language,
      uploadedFiles,
      uploadingFiles,

      UPDATE_TYPES,
      AGI_STEP,

      approveTaskList,
      redoTask,
      sendMsg,
      startIncompleteThread,
      startNewTask,
      clearCanvas,
      getLastSelectedThread,
      skipClarificationStep,
      uploadFiles,
      removeUploadedFile,

      appendToChat(partialResults) {
        if (Array.isArray(partialResults)) {
          partialResults.forEach(result => {
            if (selectedThread.value && selectedThread.value.tasks) {
              const lastTask = selectedThread.value.tasks[selectedThread.value.tasks.length - 1];
              if (lastTask) {
                lastTask.result = (lastTask.result || '') + result;
              }
            }
          });
        } else if (typeof partialResults === 'object' && partialResults.text) {
          if (selectedThread.value && selectedThread.value.tasks) {
            const lastTask = selectedThread.value.tasks[selectedThread.value.tasks.length - 1];
            if (lastTask) {
              lastTask.result = (lastTask.result || '') + partialResults.text;
            }
          }
        }
        // Trigger a re-render or emit an event to update the UI
        eventBus.emit(uiEvents.GLOBAL.UPDATE_CHAT_INTERFACE);
      },

      newQuestion,
      createNewTaskList,
      generateNewSummary,
      completeTask,
      updateTask,
      updateThread,
    }

    app.provide("hsAgi", hsAgi)
    app.config.globalProperties.$hsAgi = hsAgi
  },
}
